import * as d3 from 'd3'

export default {
  props: {
    colorScheme: {
      type: [String, Array],
      default: 'schemeDark2'
    },
    height: {
      type: Number,
      default: 300
    },
    width: {
      type: Number,
      default: 300
    },
    useTooltip: {
      type: Boolean,
      default: true
    },
    labels: {
      type: Array
    }
  },
  data: () => ({
    t: null // d3.event.transform
  }),
  computed: {
    color () {
      const color = d3.scaleOrdinal()
      if (!Array.isArray(this.colorScheme)) color.range(d3[this.colorScheme])
      else color.range(this.colorScheme)
      return color
    }
  }
}
