<template>
  <section>
    <pibot-chartist-bar v-if="stackData" :stack-data="stackData" :labels="stackLabels" :legend="computedLabels">
      <template #title>
        Budget planning for pipeline Inspection, Repair and Maintenance costs
      </template>

      <v-container class="pt-0">
        <v-row align="center" justify="end">
          <v-switch
            v-model="optimize"
            @change="toggleOptimize"
            label="Optimized for risks and costs"
            color="primary darken-1"
            class="my-0 mx-4 pa-0"
            dense
            hide-details
          />

          <v-col cols="2" class="pr-0">
            <v-select
              :items="groupOptions"
              :value="activeGroupOption"
              @change="changedGroup"
              color="primary darken-1"
              item-color="primary darken-1"
              dense
              outlined
              hide-details
            />
          </v-col>
        </v-row>
      </v-container>
    </pibot-chartist-bar>
  </section>
</template>

<script>
import optimisationMixin from '../utils/mixins/optimisation.mixin'
import styleMixin from '@/utils/mixins/styling/style.mixin'
import chartPropsMixin from '@/utils/mixins/charts/chartProps.mixin'
import chartTooltipMixin from '@/utils/mixins/charts/chartTooltip.mixin'

export default {
  name: 'pibot-optimisation-budget-planning',
  mixins: [optimisationMixin, styleMixin, chartPropsMixin, chartTooltipMixin],
  components: {
    'pibot-chartist-bar': () => import('@/components/charts/BarChartist')
  },
  data: () => ({
    data: null,
    xKey: 'year',
    yLabel: 'Costs in €',
    xLabel: 'Year'
  }),
  computed: {
    activeData () {
      if (this.data && this.data[this.activeGroup]) return this.data[this.activeGroup]
      return null
    },
    computedLabels () {
      return ['Repair and Maintenance', 'In-line Inspection', 'Spot Inspection']
    },
    groupOptions () {
      return Object.keys(this.data).filter(k => !k.includes('_optimised')).map((o, i) => ({
        text: (o === 'overview') ? 'Overview' : `Pipeline ${i}`,
        value: o
      }))
    },
    activeGroupOption () {
      return this.groupOptions.find(o => o.value.split('_')[0] === this.activeGroup.split('_')[0])
    },
    stackData () {
      if (!this.activeData) return null

      const groups = [...new Set(this.activeData.map(o => o.year))]
      const valueKeys = Object.keys(this.activeData[0]).filter(k => k !== 'year')

      const dataMap = {}
      groups.forEach(g => {
        dataMap[g] = this.activeData.filter(o => o.year === g)
      })

      const stacked = []

      // The following code will create an Array like this:
      // [ `series`
      //   [ `key`
      //     { `group/year`
      //       meta,
      //       value
      //     }
      //   ]
      // ]

      valueKeys.forEach((k) => {
        const stackGroup = []

        groups.forEach((g) => {
          // Create an Array of Objects for all data Objects of the current group
          const toPush = dataMap[g].map(o => ({
            meta: String(g),
            value: o[k]
          }))

          stackGroup.push(...toPush)
        })

        stacked.push(stackGroup)
      })

      return stacked
    },
    stackLabels () {
      return [...new Set(this.activeData.map(o => String(o.year)))]
    }
  },
  methods: {
    changedGroup (newValue) {
      this.setActiveGroup(newValue)
      this.toggleOptimize(this.optimize)
    }
  },
  mounted () {
    this.fetchIrmOptimisation().then((data) => {
      this.data = data
    }).catch(() => {})
  }
}
</script>
