import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  data: () => ({
    optimize: false
  }),
  computed: {
    ...mapState({
      activeGroup: state => state.surveys.activeGroup
    })
  },
  methods: {
    ...mapMutations({
      setActiveGroup: 'surveys/setActiveGroup'
    }),
    ...mapActions({
      fetchIrmOptimisation: 'surveys/socket_fetchIrmOptimisation'
    }),
    toggleOptimize (newValue) {
      if (!newValue) {
        if (this.activeGroup.includes('overview_')) this.setActiveGroup(`${this.activeGroup.replace('_optimised', '')}`)
        else this.setActiveGroup(`${this.activeGroup.replace('_optimised', '_overview')}`)
      } else {
        this.setActiveGroup(`${this.activeGroup.replace('_overview', '')}_optimised`)
      }
    }
  }
}
