import * as d3 from 'd3'

export default {
  data: () => {
    return {
      tooltip: null
    }
  },
  computed: {
    mouseover () {
      // DEPRECATED
      if (!this.tooltip) {
        console.error('You need to call initTooltip() first')
        return function () {}
      }

      const self = this

      return function (d, i, nodes, html) {
        if (!nodes) return
        // if (d.data && d.data.key && d.data.value) html = `<div><b>${d.data.key}:</b> ${d.value}</div>`
        // else if (d.data && d.data.group) { // Grouped plots
        //   const keys = Object.keys(d.data)
        //   keys.splice(keys.indexOf('group'), 1)

        //   html = keys.map(k => `<div><b>${k}:</b> <div>${d.data[k]}%</div></div>`)
        //   html = html.join('\n')
        // } else {
        //   html = Object.keys(d).map(k => `<div><b>${k}:</b> <div>${d[k]}</div></div>`)
        //   html = html.join('\n')
        // }
        // html = JSON.stringify(d, undefined, 2)
        if (!html) {
          html = d[1]
          if (d.data && d.data.value !== undefined) html = d.data.value
        }

        if (html) {
          self.tooltip
            .style('opacity', 1)
            .html(`<small>${html}</small>`)

          d3.select(nodes[i])
            .style('opacity', 0.7)
        }
      }
    },
    mousemove () {
      // DEPRECATED
      if (!this.tooltip) {
        console.error('You need to call initTooltip() first')
        return function () {}
      }

      const self = this

      return function () {
        self.tooltip
          .attr('class', 'pibot-chart-tooltip')
          .style('left', `calc(${event.pageX}px + 2rem)`)
          .style('top', `calc(${event.pageY}px - 0.5rem)`)
      }
    },
    mouseleave () {
      // DEPRECATED
      if (!this.tooltip) {
        console.error('You need to call initTooltip() first')
        return function () {}
      }

      const self = this

      return function (d, i, nodes) {
        if (!nodes) return
        self.tooltip
          .style('opacity', 0)
        d3.select(nodes[i])
          .style('opacity', 1)
      }
    }
  },
  methods: {
    initTooltip (randomID) {
      if (!randomID) {
        console.error('Missing required argument \'randomID\'')
        return
      }

      this.tooltip = d3.select('body')
        .append('div')
        .attr('id', `chart-tooltip-${randomID}`)
        .style('position', 'absolute')
        .style('opacity', 0)
        .style('z-index', '100')
    }
  }
}
